.Separator {
  color: var(--separator_common, var(--vkui--color_separator_primary));
}

@media (min-resolution: 2dppx) {
  .Separator {
    color: var(--separator_common, var(--vkui--color_separator_primary2x));
  }
}

@media (min-resolution: 3dppx) {
  .Separator {
    color: var(--separator_common, var(--vkui--color_separator_primary3x));
  }
}

.Separator__in:not(.Separator__in--expanded) {
  height: var(--thin-border);
  background: currentColor;
  transform-origin: center top;
}

.Separator__in--expanded {
  height: 16px;
  background: transparent;
}

.Separator--padded .Separator__in {
  margin-left: var(--vkui--size_base_padding_horizontal--regular);
  margin-right: var(--vkui--size_base_padding_horizontal--regular);
}

/*
 * CMP:
 * ModalPage
 */
.ModalPage--sizeX-regular .Separator--padded {
  padding-left: 8px;
  padding-right: 8px;
}
