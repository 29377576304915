.InitialsAvatar {
  background-color: #99a2ad;
  background-image: linear-gradient(135deg, #b1b6bd, #99a2ad);
}

.InitialsAvatar--color-red {
  background-color: #e52e40;
  background-image: linear-gradient(135deg, #ff7583, #e52e40);
}

.InitialsAvatar--color-pink {
  background-color: #e62e6b;
  background-image: linear-gradient(135deg, #ff8880, #e62e6b);
}

.InitialsAvatar--color-orange {
  background-color: #e66b2e;
  background-image: linear-gradient(135deg, #ffbf80, #e66b2e);
}

.InitialsAvatar--color-yellow {
  background-color: #e7a902;
  background-image: linear-gradient(135deg, #ffd54f, #e7a902);
}

.InitialsAvatar--color-green {
  background-color: #6cd97e;
  background-image: linear-gradient(135deg, #6cd97e, #12b212);
}

.InitialsAvatar--color-l-blue {
  background-color: #2bb4d6;
  background-image: linear-gradient(135deg, #7df1fa, #2bb4d6);
}

.InitialsAvatar--color-blue {
  background-color: #3f8ae0;
  background-image: linear-gradient(135deg, #6cf, #3f8ae0);
}

.InitialsAvatar--color-violet {
  background-color: #8f3fe0;
  background-image: linear-gradient(135deg, #d3a6ff, #8f3fe0);
}

.InitialsAvatar__text {
  font-family: var(--font-display);
  font-weight: 600;
  color: var(--white, var(--vkui--color_text_contrast));
  text-transform: uppercase;
  text-align: center;
}
