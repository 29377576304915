.SimpleCell {
  display: flex;
  align-items: center;
  min-height: 48px;
  white-space: nowrap;
  text-decoration: none;
  color: var(--text_primary, var(--vkui--color_text_primary));
  padding-left: var(--vkui--size_base_padding_horizontal--regular);
  padding-right: var(--vkui--size_base_padding_horizontal--regular);
}

.SimpleCell--mult {
  white-space: normal;
}

.SimpleCell--mult .SimpleCell__children {
  text-overflow: initial;
}

.SimpleCell__main {
  max-width: 100%;
  flex-grow: 1;
  min-width: 0;
  overflow: hidden;
}

.SimpleCell > .Icon {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 12px;
  flex-shrink: 0;
  color: var(--accent, var(--vkui--color_icon_accent));
}

.SimpleCell > .Icon--28 {
  padding-right: 16px;
}

.SimpleCell__subhead {
  color: var(--text_subhead, var(--vkui--color_text_subhead));
}

.SimpleCell__text {
  text-overflow: ellipsis;
  overflow: hidden;
}

.SimpleCell__subtitle,
.SimpleCell__extraSubtitle {
  color: var(--text_secondary, var(--vkui--color_text_secondary));
}

.SimpleCell .SimpleCell__content {
  display: flex;
  align-content: flex-start;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
}

.SimpleCell:not(.SimpleCell--mult) .SimpleCell__content {
  justify-content: flex-start;
}

.SimpleCell__children {
  color: inherit;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

.SimpleCell--mult .SimpleCell__children,
.SimpleCell--mult .SimpleCell__subtitle {
  flex: 1 1 auto;
}

.SimpleCell__badge {
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  color: var(--blue_200, var(--vkui--color_icon_accent));
}

.SimpleCell__content > *:not(:last-child) {
  margin-right: 4px;
}

.SimpleCell__indicator {
  color: var(--text_secondary, var(--vkui--color_text_secondary));
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 4px;
}

.SimpleCell__after {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  color: var(--accent, var(--vkui--color_icon_accent));
}

.SimpleCell__after > .Icon {
  padding-left: 8px;
}

.SimpleCell__after > .Icon:last-child {
  padding-right: 2px;
}

.SimpleCell__after .IconButton:last-child {
  margin-right: -12px;
}

/**
 * iOS
 */

.SimpleCell--ios .SimpleCell__main,
.SimpleCell--ios .SimpleCell__indicator {
  padding-top: 9px;
  padding-bottom: 11px;
}

.SimpleCell--ios > .Icon--28 {
  padding-left: 4px;
}

.SimpleCell--ios .SimpleCell__after .Icon--chevron_24 {
  color: var(--icon_tertiary, var(--vkui--color_icon_tertiary));
  padding-right: 4px;
  padding-left: 12px;
}

.SimpleCell--ios .SimpleCell__after .IconButton:last-child {
  margin-right: -9px;
}

/**
 * Android & VKCOM
 */

.SimpleCell--android .SimpleCell__main,
.SimpleCell--android .SimpleCell__indicator,
.SimpleCell--vkcom .SimpleCell__main,
.SimpleCell--vkcom .SimpleCell__indicator {
  padding-top: 11px;
  padding-bottom: 10px;
}

/**
 * sizeY === 'compact'
 */

.SimpleCell--sizeY-compact {
  min-height: 44px;
}

.SimpleCell--sizeY-compact .SimpleCell__main,
.SimpleCell--sizeY-compact .SimpleCell__indicator {
  padding-top: 10px;
  padding-bottom: 10px;
}

/**
 * CMP:
 * FormItem
 */
.FormItem > .SimpleCell {
  margin: 0 calc(-1 * var(--vkui--size_base_padding_horizontal--regular));
}

.SimpleCell__main > *:not(:first-child) {
  margin-top: 2px;
}
