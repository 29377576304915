.SubnavigationBar__in {
  /* Отрицательный margin нужен, чтобы компенсировать увеличенный из-за тени padding (12px -> 20px) */
  margin: -8px 0;
}

.SubnavigationBar__scrollIn {
  padding-top: calc(
    var(--vkui--size_subnavigation_bar_padding_vertical--regular) + 8px
  );
  padding-bottom: calc(
    var(--vkui--size_subnavigation_bar_padding_vertical--regular) + 8px
  );
  display: flex;
}

.SubnavigationBar__scrollIn::before,
.SubnavigationBar__scrollIn::after {
  content: "";
  min-width: var(--vkui--size_base_padding_horizontal--regular);
}
