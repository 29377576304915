.Tappable {
  position: relative;
  cursor: default;
  border-radius: var(--vkui--size_border_radius--regular);
  transition: background-color 0.15s ease-out;
}

.Tappable--hasHover,
.Tappable--hasActive {
  cursor: pointer;
}

.Tappable[disabled],
.Tappable[aria-disabled="true"] {
  cursor: default;
}

.Tappable--focus-visible {
  outline: none;
}

/**
 * active
 * increased specificity to override CellButton styles
 */
.Tappable--active-background.Tappable--active-background.Tappable--active-background {
  background-color: var(
    --background_highlighted,
    var(--vkui--color_transparent--active)
  );
}

.Tappable--active-opacity.Tappable--active-opacity.Tappable--active-opacity {
  opacity: 0.7;
}

/**
 * hover
 */
.Tappable__hoverShadow {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  overflow: hidden;
  border-radius: inherit;
}

.Tappable--hover-background > .Tappable__hoverShadow {
  background-color: var(
    --background_hover,
    var(--vkui--color_transparent--hover)
  );
}

.Tappable--hover-opacity {
  opacity: 0.8;
}

/**
 * mouse
 */
.Tappable--mouse {
  transition: opacity 0.15s ease-out;
}

.Tappable--mouse .Tappable__hoverShadow {
  transition: background-color 0.15s ease-out;
}

/**
 * iOS tappable
 */
.Tappable--ios.Tappable--active-background {
  transition: none;
}

.Tappable .Tappable__waves {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  overflow: hidden;
  border-radius: inherit;
  /* Fix for Safari: css animation + border-radius + overflow ignores parent border-radius */
  will-change: transform;
}

.Tappable .Tappable__wave {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
  opacity: 0;
  content: "";
  border-radius: 50%;
  background: rgba(127, 127, 127, 0.1);
  animation: vkui-animation-wave 0.3s var(--android-easing);
}

/**
 * overrides
 */
.Tappable--sizeX-compact {
  border-radius: 0;
}

/**
 * Animations
 */
@keyframes vkui-animation-wave {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  30% {
    opacity: 1;
  }

  100% {
    transform: scale(8);
    opacity: 0;
  }
}
