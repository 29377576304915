.Tooltip {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  white-space: normal;
}

.Tooltip__container {
  position: absolute;
}

.Tooltip__content {
  background-color: var(--vkui--color_background_modal);
  box-shadow: var(--vkui--elevation3);
  padding: 9px 12px 10px;
  border-radius: var(--vkui--size_border_radius--regular);
  color: var(--vkui--color_text_primary);
  max-width: 220px;
}

.Tooltip__arrow {
  color: var(--vkui--color_background_modal);
}

.Tooltip--accent .Tooltip__content {
  background-color: var(--vkui--color_background_accent_tint);
  color: var(--vkui--color_text_contrast);
}

.Tooltip--accent .Tooltip__arrow {
  color: var(--vkui--color_background_accent_tint);
}

.Tooltip--white .Tooltip__content {
  background-color: var(--vkui--color_background_contrast);
  color: var(--vkui--color_text_primary_invariably);
}

.Tooltip--white .Tooltip__arrow {
  color: var(--vkui--color_background_contrast);
}

.Tooltip--black .Tooltip__content {
  background-color: var(--vkui--color_background_contrast_inverse);
  color: var(--vkui--color_text_contrast);
}

.Tooltip--black .Tooltip__arrow {
  color: var(--vkui--color_background_contrast_inverse);
}

.Tooltip--inversion .Tooltip__content {
  background-color: var(--vkui--color_background_modal_inverse);
  color: var(--vkui--color_text_contrast_themed);
}

.Tooltip--inversion .Tooltip__arrow {
  color: var(--vkui--color_background_modal_inverse);
}
