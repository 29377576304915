.HorizontalCell {
  display: flex;
}

.HorizontalCell__image {
  display: flex;
  justify-content: center;
}

.HorizontalCell__body {
  text-decoration: none;
}

.HorizontalCell__content {
  color: var(--text_primary, var(--vkui--color_text_primary));
  word-break: break-all;
  text-overflow: ellipsis;
}

.HorizontalCell__subtitle {
  color: var(--text_secondary, var(--vkui--color_text_secondary));
}

.HorizontalCell__content .HorizontalCell__subtitle {
  margin-top: 2px;
}

.HorizontalCell--s {
  max-width: 80px;
}

.HorizontalCell__image {
  padding: 4px 6px;
}

.HorizontalCell__content {
  padding: 2px 6px 8px;
  text-align: left;
}

.HorizontalCell--s .HorizontalCell__image {
  padding: 4px 8px;
}

.HorizontalCell--s .HorizontalCell__content {
  padding: 2px 4px 8px;
  text-align: center;
}

.HorizontalCell--m {
  width: 100px;
}

.HorizontalCell--l {
  width: auto;
}

.HorizontalCell:first-child::before,
.HorizontalCell:last-child::after {
  content: "";
  min-width: 10px;
}

.HorizontalCell--s:first-child::before,
.HorizontalCell--s:last-child::after {
  min-width: 8px;
}

.HorizontalCell--s:first-child,
.HorizontalCell--s:last-child {
  max-width: 88px;
}

.HorizontalCell--m:first-child,
.HorizontalCell--m:last-child {
  width: 110px;
}
