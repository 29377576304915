.Checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--vkui--size_base_padding_horizontal--regular);
  min-width: var(--vkui--size_field_height--regular);
  min-height: var(--vkui--size_field_height--regular);
}

.FormItem .Checkbox {
  width: 100%;
  box-sizing: content-box;
  margin: 0 calc(-1 * var(--vkui--size_base_padding_horizontal--regular));
}

.Checkbox__icon {
  flex-shrink: 0;
  margin-right: 12px;
}

.Checkbox__icon--on {
  color: var(--accent, var(--vkui--color_icon_accent));
  display: none;
}

.Checkbox__icon--indeterminate {
  color: var(--accent, var(--vkui--color_icon_accent));
  display: none;
}

.Checkbox__icon--off {
  color: var(--icon_tertiary, var(--vkui--color_icon_tertiary));
}

.Checkbox__content {
  flex-grow: 1;
  color: var(--text_primary, var(--vkui--color_text_primary));
  word-break: break-word;
}

.Checkbox__description {
  color: var(--text_secondary, var(--vkui--color_text_secondary));
  display: block;
  margin-bottom: 12px;
  margin-top: 2px;
}

.Checkbox__children {
  margin-top: 12px;
}

.Checkbox__children:last-child {
  margin-bottom: 12px;
}

.Checkbox__input:checked ~ .Checkbox__icon--on {
  display: flex;
}

.Checkbox__input:indeterminate ~ .Checkbox__icon--indeterminate,
.Checkbox__input:checked ~ .Checkbox__icon--off {
  display: none;
}

.Checkbox__input:indeterminate ~ .Checkbox__icon--indeterminate {
  display: flex;
}

.Checkbox__input:indeterminate ~ .Checkbox__icon--on,
.Checkbox__input:indeterminate ~ .Checkbox__icon--off {
  display: none;
}

.Checkbox__input[disabled] ~ * {
  opacity: var(--vkui--opacity_disable_accessibility);
}

/**
 * sizeY COMPACT
 */

.Checkbox--sizeY-compact {
  min-width: var(--vkui--size_field_height--compact);
  min-height: var(--vkui--size_field_height--compact);
}

.Checkbox--sizeY-compact .Checkbox__children {
  margin-top: 8px;
}

.Checkbox--sizeY-compact .Checkbox__children:last-child {
  margin-bottom: 8px;
}

.Checkbox--sizeY-compact .Checkbox__description {
  margin-bottom: 8px;
}

.Checkbox--simple {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 50%;
}

.Checkbox--simple .Checkbox__content {
  display: none;
}

.Checkbox--simple .Checkbox__icon {
  margin: 0;
}
