.FormStatus {
  border-radius: 10px;
  padding: 12px;
  background: var(--field_background, var(--vkui--color_field_background));
  color: var(--text_subhead, var(--vkui--color_text_subhead));
}

.FormStatus__header,
.FormStatus b:first-child {
  color: var(--text_primary, var(--vkui--color_text_primary));
}

.FormStatus__header:not(:last-child),
.FormStatus b:first-child {
  margin-bottom: 2px;
}

.FormStatus--error {
  background: var(
    --field_error_background,
    var(--vkui--color_background_negative_tint)
  );
  color: var(--field_error_border, var(--vkui--color_text_negative));
}

.FormStatus--error .FormStatus__header,
.FormStatus--error .FormStatus b:first-child {
  color: currentColor;
}

.FormStatus b:first-child {
  /* хак для ошибок из API, текст в которых приходит с <b>в начале</b> */
  display: block;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}
