.MiniInfoCell {
  display: flex;
  padding: 6px var(--vkui--size_base_padding_horizontal--regular);
}

.MiniInfoCell--lvl-primary {
  color: var(--text_primary, var(--vkui--color_text_primary));
}

.MiniInfoCell--lvl-secondary {
  color: var(--text_subhead, var(--vkui--color_text_subhead));
}

.MiniInfoCell__icon {
  margin-right: 12px;
  color: var(--icon_outline_secondary, var(--vkui--color_icon_secondary));
}

.MiniInfoCell__content {
  flex: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.MiniInfoCell--wr-short .MiniInfoCell__content {
  white-space: initial;
  display: -webkit-box;
  max-height: 60px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.MiniInfoCell--wr-full .MiniInfoCell__content {
  overflow: inherit;
  white-space: initial;
}

.MiniInfoCell__after {
  margin-left: 12px;
  margin-top: -2px;
  margin-bottom: -2px;
}

/**
* TODO Когда избавимся от appearance токенов - объединить классы
 */

.MiniInfoCell--md-add {
  color: var(--accent, var(--vkui--color_text_link));
}

.MiniInfoCell--md-add .MiniInfoCell__icon {
  color: var(--accent, var(--vkui--color_icon_accent));
}

.MiniInfoCell--md-more {
  color: var(--link_alternate, var(--vkui--color_text_link));
}

.MiniInfoCell--md-more .MiniInfoCell__icon {
  color: var(--link_alternate, var(--vkui--color_icon_accent));
}

.MiniInfoCell--md-more {
  padding-top: 10px;
  padding-bottom: 10px;
}
