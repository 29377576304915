.CustomSelectDropdown {
  overflow: hidden;
  background-color: var(
    --modal_card_background,
    var(--vkui--color_background_content)
  );
  box-sizing: border-box;
  border: var(--thin-border) solid
    var(--field_border, var(--vkui--color_field_border_alpha));
  box-shadow: var(--vkui--elevation1);
  border-radius: 8px;
}

.CustomSelectDropdown--wide {
  width: 100%;
}

.CustomSelectDropdown--bottom {
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.CustomSelectDropdown--top {
  bottom: 100%;
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: var(--vkui--elevation1_invert_y);
}

.CustomSelectDropdown__fetching {
  padding: 12px 0;
}

.CustomSelectDropdown__in {
  max-height: 160px;
}

/**
 * CMP:
 * CalendarHeader
 */
.CalendarHeader__pickers .CustomSelectDropdown__in {
  max-height: 184px;
}
