.CellCheckbox__icon {
  color: var(--icon_tertiary, var(--vkui--color_icon_tertiary));
}

.CellCheckbox__input,
.CellCheckbox__icon {
  display: none;
}

.CellCheckbox__icon--on {
  color: var(--accent, var(--vkui--color_icon_accent));
}

.CellCheckbox__input:not(:checked) ~ .CellCheckbox__icon--off,
.CellCheckbox__input:checked ~ .CellCheckbox__icon--on {
  display: block;
}
