.Counter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Counter__in {
  display: block;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  box-sizing: border-box;
}

.Counter--primary {
  background: var(
    --counter_primary_background,
    var(--vkui--color_background_accent)
  );
  color: var(--counter_primary_text, var(--vkui--color_text_contrast));
}

.Counter--secondary {
  background: var(
    --counter_secondary_background,
    var(--vkui--color_icon_tertiary)
  );
  color: var(--counter_secondary_text, var(--vkui--color_text_contrast));
}

.Counter--prominent {
  background: var(
    --counter_prominent_background,
    var(--vkui--color_background_negative)
  );
  color: var(--counter_prominent_text, var(--vkui--color_text_contrast));
}

.Counter--contrast {
  background: var(--vkui--color_background_contrast);
  color: var(--vkui--color_text_accent);
}

.Counter--s-m {
  height: 24px;
  min-width: 24px;
  border-radius: 12px;
}

.Counter--s-s {
  height: 18px;
  min-width: 18px;
  border-radius: 9px;
}

/**
 * CMP:
 * Button
 */
.Button--lvl-primary.Button--clr-accent .Counter {
  background-color: var(
    --button_primary_foreground,
    var(--vkui--color_background_contrast)
  );
  color: var(--button_primary_background, var(--vkui--color_text_contrast));
}

.Button--lvl-secondary.Button--clr-accent .Counter {
  background-color: var(
    --button_secondary_foreground,
    var(--vkui--color_background_accent)
  );
  color: var(--background_content, var(--vkui--color_text_contrast_themed));
}

.Button--lvl-tertiary.Button--clr-accent .Counter {
  background-color: var(
    --button_tertiary_foreground,
    var(--vkui--color_background_accent)
  );
  color: var(--background_content, var(--vkui--color_text_contrast_themed));
}

.Button--lvl-outline.Button--clr-accent .Counter {
  background-color: var(
    --button_outline_foreground,
    var(--vkui--color_background_accent)
  );
  color: var(--background_content, var(--vkui--color_text_contrast_themed));
}

.Button--lvl-primary.Button--clr-positive .Counter {
  background-color: var(
    --button_commerce_foreground,
    var(--vkui--color_background_contrast)
  );
  color: var(--button_commerce_background, var(--vkui--color_text_positive));
}

.Button--lvl-secondary.Button--clr-positive .Counter,
.Button--lvl-tertiary.Button--clr-positive .Counter,
.Button--lvl-outline.Button--clr-positive .Counter {
  background-color: var(
    --button_commerce_background,
    var(--vkui--color_background_positive)
  );
  color: var(--button_commerce_foreground, var(--vkui--color_text_contrast));
}

.Button--lvl-primary.Button--clr-negative .Counter {
  background-color: var(--white, var(--vkui--color_background_contrast));
  color: var(--destructive, var(--vkui--color_text_negative));
}

.Button--lvl-secondary.Button--clr-negative .Counter,
.Button--lvl-tertiary.Button--clr-negative .Counter,
.Button--lvl-outline.Button--clr-negative .Counter {
  background-color: var(--destructive, var(--vkui--color_background_negative));
  color: var(--white, var(--vkui--color_text_contrast));
}

.Button--lvl-primary.Button--clr-neutral .Counter {
  background-color: var(
    --media_overlay_button_background,
    var(--vkui--color_background_content)
  );
  color: var(--vkui--color_text_primary_invariably);
}

.Button--lvl-secondary.Button--clr-neutral .Counter,
.Button--lvl-tertiary.Button--clr-neutral .Counter,
.Button--lvl-outline.Button--clr-neutral .Counter {
  background-color: var(--accent, var(--vkui--color_background_accent));
  color: var(--white, var(--vkui--color_text_contrast));
}

.Button--lvl-primary.Button--clr-overlay .Counter {
  background-color: var(
    --media_overlay_button_foreground,
    var(--vkui--color_icon_primary_invariably)
  );
  color: var(
    --media_overlay_button_background,
    var(--vkui--color_text_contrast)
  );
}

.Button--lvl-secondary.Button--clr-overlay .Counter,
.Button--lvl-tertiary.Button--clr-overlay .Counter,
.Button--lvl-outline.Button--clr-overlay .Counter {
  background-color: var(
    --media_overlay_button_background,
    var(--vkui--color_background_contrast)
  );
  color: var(
    --media_overlay_button_foreground,
    var(--vkui--color_text_primary_invariably)
  );
}

/**
 * CMP:
 * WriteBarIcon
 */
.WriteBarIcon .Counter {
  color: var(--button_commerce_foreground, var(--vkui--color_text_contrast));
  background: var(
    --button_commerce_background,
    var(--vkui--color_background_positive)
  );
}

/**
 * CMP:
 * TabsItem
 */
/* TODO v5.0.0 Удалить. Относится к <Tabs mode="segmented" />. По дизайну надо использовать <Counter mode="prominent" />  */
/* [Начало] */
.TabsItem .Counter--secondary {
  background-color: var(--panel_tab_inactive_text);
  color: var(--white);
}

.TabsItem--selected .Counter--secondary {
  background-color: var(--panel_tab_active_text);
  color: var(--panel_tab_active_background);
}
/* [Конец] */

/* TODO v5.0.0 Удалить mode="segmented" */
/* [Начало] */
.TabsItem--ios.TabsItem--segmented .Counter--secondary {
  background-color: var(--segmented_control_tint);
  color: var(--background_content);
}

.TabsItem--ios.TabsItem--segmented.TabsItem--selected .Counter--secondary {
  background-color: var(--background_content);
  color: var(--segmented_control_tint);
}
/* [Конец] */

/**
 * CMP:
 * SubnavigationButton
 */
.SubnavigationButton--selected:not(.SubnavigationButton--mode-tertiary)
  .Counter {
  background-color: var(
    --button_primary_foreground,
    var(--vkui--color_background_contrast)
  );
  color: var(--button_primary_background, var(--vkui--color_text_accent));
}
