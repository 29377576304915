.FixedLayout {
  position: fixed;
  box-sizing: border-box;
  /**
   * ⚠️ WARNING ⚠️
   * `left: auto` решает следующие задачи:
   * 1. При фиксированной ширине компонента позиционирует компонент по центру.
   * 2. При использовании `SplitLayout` позиционирует компонент с права от `<SplitCol fixed />`.
   *
   * Подробности можно почитать здесь https://github.com/VKCOM/VKUI/pull/2472
   *
   * Единственное, есть проблема при срабатывании insets в landscape ориентации экрана (https://github.com/VKCOM/VKUI/issues/2422).
   */
  left: auto;
  z-index: 3;
  padding-left: var(--safe-area-inset-left);
  padding-right: var(--safe-area-inset-right);
}

.FixedLayout--filled {
  background: var(--background_content, var(--vkui--color_background_content));
}

.FixedLayout--top {
  width: 100%;
  top: 0;
}

.FixedLayout--bottom {
  width: 100%;
  bottom: 0;
  padding-bottom: var(--safe-area-inset-bottom);
}

/**
 * CMP:
 * Epic
 */
.Epic .FixedLayout--bottom {
  padding-bottom: calc(var(--tabbar_height) + var(--safe-area-inset-bottom));
}

/**
 * CMP:
 * PanelHeader
 */
/*
 * TODO v5.0.0 объединить после удаления appearance токенов
*/
.PanelHeader ~ .FixedLayout--top,
.PanelHeader ~ * .FixedLayout--top:not(.PanelHeader__fixed) {
  top: calc(
    var(--panelheader_height, var(--vkui--size_panel_header_height--regular)) +
      var(--safe-area-inset-top)
  );
}

.PanelHeader--ios ~ .FixedLayout--top,
.PanelHeader--ios ~ * .FixedLayout--top:not(.PanelHeader__fixed) {
  top: calc(
    var(
        --panelheader_height_ios,
        var(--vkui--size_panel_header_height--regular)
      ) + var(--safe-area-inset-top)
  );
}

.PanelHeader--vkcom ~ .FixedLayout--top,
.PanelHeader--vkcom ~ * .FixedLayout--top:not(.PanelHeader__fixed) {
  top: calc(
    var(
        --panelheader_height_vkcom,
        var(--vkui--size_panel_header_height--regular)
      ) + var(--safe-area-inset-top)
  );
}

/**
 * CMP:
 * PanelHeaderContext
 */
.FixedLayout.PanelHeaderContext {
  z-index: 4;
}
