.TextTooltip {
  border-radius: var(--vkui--size_border_radius--regular);
  background-color: var(--vkui--color_background_modal);
  padding: 8px 12px 9px;
  color: var(--vkui--color_text_primary);
  box-shadow: var(--vkui--elevation3);
  animation: vkui-popper-fadein 0.2s ease;
}

.TextTooltip__arrow {
  color: var(--vkui--color_background_modal);
}

.TextTooltip--accent {
  background-color: var(--vkui--color_background_accent_tint);
  color: var(--vkui--color_text_contrast);
}

.TextTooltip--accent .TextTooltip__arrow {
  color: var(--vkui--color_background_accent_tint);
}

.TextTooltip--white {
  background-color: var(--vkui--color_background_contrast);
  color: var(--vkui--color_text_primary_invariably);
}

.TextTooltip--white .TextTooltip__arrow {
  color: var(--vkui--color_background_contrast);
}

.TextTooltip--black {
  background-color: var(--vkui--color_background_contrast_inverse);
  color: var(--vkui--color_text_contrast);
}

.TextTooltip--black .TextTooltip__arrow {
  color: var(--vkui--color_background_contrast_inverse);
}

.TextTooltip--inversion {
  background-color: var(--vkui--color_background_modal_inverse);
  color: var(--vkui--color_text_contrast_themed);
}

.TextTooltip--inversion .TextTooltip__arrow {
  color: var(--vkui--color_background_modal_inverse);
}
