.HorizontalScrollArrow {
  position: absolute;
  cursor: pointer;
  user-select: auto;
  top: 0;
  height: 100%;
  padding: 0;
  opacity: 0;
  z-index: 3;
  border: none;
  background-color: transparent;
  transition: opacity 0.15s;
  transition-timing-function: var(--android-easing);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.HorizontalScrollArrow__icon {
  background-color: var(
    --modal_card_background,
    var(--vkui--color_background_modal)
  );
  color: var(--icon_secondary, var(--vkui--color_icon_secondary));
  box-shadow: var(--vkui--elevation3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.HorizontalScrollArrow--m .HorizontalScrollArrow__icon {
  width: 28px;
  height: 28px;
  border-radius: 14px;
}

.HorizontalScrollArrow--l .HorizontalScrollArrow__icon {
  width: 40px;
  height: 40px;
  border-radius: 24px;
}

.HorizontalScrollArrow--left {
  padding-left: var(--vkui--size_base_padding_horizontal--regular, 16px);
  left: 0;
}

.HorizontalScrollArrow--right {
  padding-right: var(--vkui--size_base_padding_horizontal--regular, 16px);
  right: 0;
}

/*
 * iOS
 */
.HorizontalScrollArrow--ios .HorizontalScrollArrow {
  transition-timing-function: var(--ios-easing);
}

/**
 * CMP: Gallery, HorizontalScroll
 */
.Gallery:hover .HorizontalScrollArrow,
.HorizontalScroll:hover .HorizontalScrollArrow {
  opacity: var(--vkui--opacity_disable_accessibility);
}

.Gallery:hover .HorizontalScrollArrow:hover,
.HorizontalScroll:hover .HorizontalScrollArrow:hover,
.HorizontalScroll--withConstArrows .HorizontalScrollArrow,
.HorizontalScroll--withConstArrows:hover .HorizontalScrollArrow {
  opacity: 1;
}
