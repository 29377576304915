.DateInput__input {
  flex-grow: 1;
  margin-left: 10px;
  margin-right: 14px;
  font-size: 16px;
  z-index: 6;
  cursor: text;
  white-space: nowrap;
  user-select: text;
  font-variant-numeric: tabular-nums;
}

.DateInput__input-time-divider {
  letter-spacing: 6px !important;
}

.DateInput--sizeY-compact .DateInput__input {
  font-size: 15px;
  margin-right: 22px;
}
