.Tabbar {
  position: fixed;
  z-index: 2;
  bottom: 0;
  left: 0;
  width: 100%;
  height: var(--tabbar_height);
  padding-bottom: var(--safe-area-inset-bottom);
  box-sizing: content-box;
  background: var(
    --header_alternate_background,
    var(--vkui--color_background_tertiary)
  );
}

.Tabbar__in {
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.Tabbar--shadow {
  box-shadow: var(--vkui--elevation3);
}

/**
 * iOS
 */

.Tabbar--ios.Tabbar--shadow {
  box-shadow: none;
}

.Tabbar--ios.Tabbar--shadow::before {
  position: absolute;
  bottom: 100%;
  left: 0;
  width: 100%;
  height: 1px;
  background: var(
    --separator_common,
    var(--vkui--color_separator_primary_alpha)
  );
  transform-origin: center bottom;
  content: "";
}

@media (min-resolution: 2dppx) {
  .Tabbar--ios::before {
    transform: scaleY(0.5);
  }
}

@media (min-resolution: 3dppx) {
  .Tabbar--ios::before {
    transform: scaleY(0.33);
  }
}
