.TabsItem {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  min-width: 0;
  max-width: 100%;
  min-height: 32px;
  border-radius: var(--vkui--size_border_radius--regular);
  transition: background-color 150ms ease-out;
}

.TabsItem--withGaps {
  margin-top: 8px;
  margin-left: 6px;
  margin-bottom: 8px;
}

/* TODO v5.0.0 новая адаптивность */
.TabsItem--sizeY-compact {
  padding-top: 2px;
  padding-bottom: 2px;
  min-height: 28px;
}

.TabsItem[disabled] {
  opacity: var(--vkui--opacity_disable_accessibility);
}

/* mode="default" */
.TabsItem--default {
  position: relative;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 0;
  padding: 12px 16px;
  min-height: 48px;
}

/* TODO v5.0.0 новая адаптивность */
.TabsItem--sizeY-compact.TabsItem--default {
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 44px;
}

.TabsItem--hover.TabsItem--default {
  background-color: var(--vkui--color_transparent--hover);
}

/* mode="accent" */
.TabsItem--accent {
  transition: background-color 150ms ease-out, box-shadow 150ms ease-out;
}

.TabsItem--selected.TabsItem--accent {
  background-color: var(
    --header_alternate_background,
    var(--vkui--color_background_modal)
  );
  box-shadow: var(--vkui--elevation2);
}

.TabsItem--hover.TabsItem--accent {
  background-color: var(
    --control_background,
    var(--vkui--color_background_secondary)
  );
}

.TabsItem--accent::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: var(--thin-border) solid transparent;
  border-radius: inherit;
  pointer-events: none;
}

.TabsItem--selected.TabsItem--accent::before {
  border-color: var(--separator_common, var(--vkui--color_separator_primary));
}

/* mode="secondary" */
.TabsItem--selected.TabsItem--secondary {
  background-color: var(--vkui--color_background_secondary_alpha);
}

.TabsItem--hover.TabsItem--secondary {
  background-color: var(--vkui--color_background_secondary_alpha--hover);
}

/* Элемент */
.TabsItem__before {
  margin-right: 6px;
  color: var(--icon_outline_medium, var(--vkui--color_icon_medium));
  transition: color 150ms ease-out;
}

.TabsItem--selected .TabsItem__before {
  color: var(--vkui--color_icon_primary);
}

.TabsItem--selected.TabsItem--accent .TabsItem__before {
  color: var(
    --button_secondary_foreground,
    var(--vkui--color_icon_accent_themed)
  );
}

.TabsItem--selected.TabsItem--secondary .TabsItem__before {
  opacity: 0.72;
}

/* Элемент */
.TabsItem__label {
  max-width: 100%;
  overflow: hidden;
  color: var(--text_secondary, var(--vkui--color_text_secondary));
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: color 150ms ease-out;
  min-width: 0;
}

.TabsItem--selected .TabsItem__label {
  color: var(--text_primary, var(--vkui--color_text_primary));
}

.TabsItem--selected.TabsItem--accent .TabsItem__label {
  color: var(
    --button_secondary_foreground,
    var(--vkui--color_text_accent_themed)
  );
}

.TabsItem--selected.TabsItem--secondary .TabsItem__label {
  opacity: 0.72;
}

/* Элемент */
.TabsItem__status {
  margin-left: 6px;
}

.TabsItem__status--count {
  color: var(--text_tertiary, var(--vkui--color_text_tertiary));
}

/* Элемент */
.TabsItem__after {
  margin-left: 6px;
  color: var(--header_tint_alternate, var(--vkui--color_icon_accent_themed));
}

/* TODO удалить блок в v5.0.0 */
.TabsItem--selected.TabsItem--accent .TabsItem__after {
  color: var(
    --button_secondary_foreground,
    var(--vkui--color_icon_accent_themed)
  );
}

/* Элемент */
.TabsItem__underline {
  position: absolute;
  left: 16px;
  right: 16px;
  bottom: 5px;
  height: 2px;
  border-radius: 2px;
  background-color: var(--accent, var(--vkui--color_background_accent));
  opacity: 0;
  transition: opacity 150ms ease-out;
  pointer-events: none;
}

/* TODO v5.0.0 новая адаптивность */
.TabsItem--sizeY-compact .TabsItem__underline {
  bottom: 3px;
}

.TabsItem__underline[data-selected="true"] {
  opacity: 1;
}

/**
 * CMP:
 * Tabs
 */
.Tabs--vkcom .TabsItem {
  flex-grow: 0;
  min-width: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.Tabs--vkcom .TabsItem__underline {
  right: 2px;
  bottom: 0;
  left: 2px;
}

/**
 * CMP:
 * HorizontalScroll
 */
.HorizontalScroll .TabsItem {
  min-width: 64px;
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 0;
}

/* TODO v5.0.0 Удалить mode="segmented" */
/* [Начало] */
.TabsItem--ios.TabsItem--segmented {
  border: 1px solid var(--segmented_control_tint);
  padding: 7px 12px;
  max-width: 100%;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 0;
  border-radius: 0;
}

.TabsItem--ios.TabsItem--segmented:not(:first-child) {
  border-left: none;
}

.TabsItem--ios.TabsItem--segmented:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.TabsItem--ios.TabsItem--segmented:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.TabsItem--ios.TabsItem--segmented.TabsItem--selected {
  background-color: var(--segmented_control_tint);
  color: var(--background_content);
}

.TabsItem--ios.TabsItem--segmented:not(.TabsItem--selected).TabsItem--active {
  background-color: var(--separator_common);
}

.Tabs--header .TabsItem--ios.TabsItem--segmented.TabsItem--selected {
  background-color: var(--header_tint_alternate);
  color: var(--header_background);
}

.TabsItem--ios.TabsItem--segmented .TabsItem__label {
  color: var(--segmented_control_tint);
}

.TabsItem--ios.TabsItem--segmented.TabsItem--selected .TabsItem__label {
  color: var(--background_content);
}

.PanelHeader--ios
  .TabsItem--segmented:not(.TabsItem--selected).TabsItem--active
  .TabsItem__label {
  opacity: 0.7;
}

.PanelHeader--ios .TabsItem--segmented {
  border-color: var(--header_tint_alternate);
  color: var(--header_tint_alternate);
}
/* [Конец] */
