.ModalPageHeader {
  --safe-area-inset-top: 0;

  position: relative;
  z-index: 2;
}

.ModalPageHeader--withGaps {
  padding-right: 4px;
  padding-left: 4px;
}

.ModalPageHeader--desktop.ModalPageHeader--withGaps {
  padding-right: 8px;
  padding-left: 8px;
}

.ModalPageHeader .Icon--dismiss_24, /* Note: <Icon24Dismiss /> по документации используется только для iOS  */
.ModalPageHeader .Icon--cancel_24 {
  color: var(--icon_secondary, var(--vkui--color_icon_secondary));
}
