.PromoBanner {
  width: 100%;
  box-sizing: border-box;
  outline: var(--thin-border) solid
    var(--separator_alpha, var(--vkui--color_separator_primary_alpha));
  color: var(--text_primary, var(--vkui--color_text_primary));
  background: var(--background_content, var(--vkui--color_background_content));
  padding-bottom: 10px;
  position: relative;
}

.PromoBanner__head {
  display: flex;
  color: var(--text_secondary, var(--vkui--color_text_secondary));
  padding: 14px var(--vkui--size_base_padding_horizontal--regular) 10px;
}

.PromoBanner__age {
  margin-left: 6px;
}

.PromoBanner__close {
  position: absolute;
  top: 0;
  right: 0;
  width: 48px;
  height: 48px;
  color: var(
    --placeholder_icon_foreground_primary,
    var(--vkui--color_icon_secondary)
  );
  display: flex;
  justify-content: center;
  align-items: center;
}

.PromoBanner__close:active {
  opacity: 0.7;
}

.PromoBanner__pixels {
  display: none;
}
