.RichTooltip {
  box-shadow: var(--vkui--elevation3);
  border-radius: var(--vkui--size_border_radius--regular);
  background-color: var(
    --background_content,
    var(--vkui--color_background_modal)
  );
  animation: vkui-popper-fadein 0.2s ease;
  color: var(--vkui--color_text_primary);
}

.RichTooltip__arrow {
  color: var(--background_content, var(--vkui--color_background_modal));
}

.RichTooltip--accent {
  background-color: var(--vkui--color_background_accent_tint);
  color: var(--vkui--color_text_contrast);
}

.RichTooltip--accent .RichTooltip__arrow {
  color: var(--vkui--color_background_accent_tint);
}

.RichTooltip--white {
  background-color: var(--vkui--color_background_contrast);
  color: var(--vkui--color_text_primary_invariably);
}

.RichTooltip--white .RichTooltip__arrow {
  color: var(--vkui--color_background_contrast);
}

.RichTooltip--black {
  background-color: var(--vkui--color_background_contrast_inverse);
  color: var(--vkui--color_text_contrast);
}

.RichTooltip--black .RichTooltip__arrow {
  color: var(--vkui--color_background_contrast_inverse);
}

.RichTooltip--inversion {
  background-color: var(--vkui--color_background_modal_inverse);
  color: var(--vkui--color_text_contrast_themed);
}

.RichTooltip--inversion .RichTooltip__arrow {
  color: var(--vkui--color_background_modal_inverse);
}
