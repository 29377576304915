.CalendarRange {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 610px;
  background: var(--vkui--color_background_modal);
  border: 0.5px solid var(--vkui--color_separator_primary);
  box-sizing: border-box;
  box-shadow: var(--vkui--elevation1);
  border-radius: 8px;
  padding: 12px;
}

.CalendarRange__inner {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.CalendarRange__inner:not(:last-child) {
  margin-right: 24px;
}

.CalendarRange__header {
  margin-bottom: 4px;
  z-index: 1;
}
