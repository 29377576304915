.Text {
  display: block;
  margin: 0;
  font-size: var(--vkui--font_text--font_size--regular);
  line-height: var(--vkui--font_text--line_height--regular);
  font-weight: var(--vkui--font_text--font_weight--regular);
}

/* TODO v5.0.0 перевести на новую адаптивность */
.Text--sizeY-compact {
  font-size: var(--vkui--font_text--font_size--compact);
  line-height: var(--vkui--font_text--line_height--compact);
}

/* TODO удалить перед релизом 5.0.0 */
.Text--w-regular {
  font-weight: 400;
}

/* TODO удалить перед релизом 5.0.0 */
.Text--w-medium {
  font-weight: 500;
}

/* TODO удалить перед релизом 5.0.0 */
.Text--w-semibold {
  font-weight: 600;
}

.Text--w-1 {
  font-weight: var(--vkui--font_weight_accent1);
}

.Text--w-2 {
  font-weight: var(--vkui--font_weight_accent2);
}

.Text--w-3 {
  font-weight: var(--vkui--font_weight_accent3);
}

/**
 * CMP:
 * PanelHeaderContent
 */
.PanelHeaderContent__children .Text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
