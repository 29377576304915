.ModalDismissButton {
  position: absolute;
  justify-content: center;
  top: 0;
  right: -56px;
  width: 56px;
  height: 56px;
  padding: 18px;
  box-sizing: border-box;
  color: var(--white, var(--vkui--color_icon_contrast));
  transition: opacity 0.15s ease-out;
}

.ModalDismissButton::before {
  display: block;
  content: "";
  top: 14px;
  right: 14px;
  bottom: 14px;
  left: 14px;
  background: var(--vkui--color_overlay_primary);
  border-radius: 50%;
  position: absolute;
}

/* fixes icon misplacement on Safari in some cases */
.ModalDismissButton .Icon {
  transform: translateX(0);
}

.ModalDismissButton--hover.ModalDismissButton::before {
  background: var(--vkui--color_overlay_primary--hover);
}

.ModalDismissButton--active.ModalDismissButton::before {
  background: var(--vkui--color_overlay_primary--active);
}
