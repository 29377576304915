.Dropdown {
  position: relative;
  animation: vkui-popper-fadein 0.2s ease;
  background: var(--modal_card_background, var(--vkui--color_background_modal));
  border-radius: var(--vkui--size_border_radius--regular);
  box-shadow: var(--vkui--elevation3);
}

/* Создаём "Safe Zone" */
.Dropdown::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: calc(-1 * var(--popover-safe-zone-padding));
  right: calc(-1 * var(--popover-safe-zone-padding));
  bottom: calc(-1 * var(--popover-safe-zone-padding));
  left: calc(-1 * var(--popover-safe-zone-padding));
}
