.CalendarTime {
  display: flex;
  align-items: center;
}

.CalendarTime__picker {
  width: 77px;
}

.CalendarTime__divider {
  margin-left: 6px;
  margin-right: 6px;
  color: var(--vkui--color_text_primary);
}

.CalendarTime__button {
  margin-left: auto;
}
