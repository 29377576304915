.Banner {
  margin: 12px 0;
  padding: 0 var(--vkui--size_base_padding_horizontal--regular);
  color: var(--text_primary, var(--vkui--color_text_primary));
}

.Banner__in {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  padding: 12px 12px 12px 16px;
  background-color: var(
    --content_tint_background,
    var(--vkui--color_background_secondary)
  );
  border-radius: var(--vkui--size_border_radius--regular);
  overflow: hidden;
}

.Banner__in::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  border: var(--thin-border) solid
    var(--image_border, var(--vkui--color_image_border_alpha));
  border-radius: inherit;
  pointer-events: none;
  box-sizing: border-box;
  z-index: 2;
}

.Banner__before {
  position: relative;
  margin-right: 12px;
  z-index: 2;
}

.Banner__content {
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-width: 0;
  z-index: 2;
}

.Banner__header {
  display: block;
}

.Banner__subheader {
  display: block;
  color: var(--text_subhead, var(--vkui--color_text_subhead));
}

.Banner__text {
  color: var(--vkui--color_text_subhead);
}

.Banner__bg {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.Banner__bg,
.Banner__bg > * {
  width: 100%;
  height: 100%;
}

.Banner__aside {
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  width: 28px;
  color: var(--icon_tertiary, var(--vkui--color_icon_tertiary));
}

.Banner__dismiss {
  position: absolute;
  top: 2px;
  right: 2px;
  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  color: var(--icon_secondary, var(--vkui--color_icon_secondary));
  z-index: 3;
}

.Banner__actions {
  margin-bottom: -6px;
}

.Banner__subheader:not(:first-child),
.Banner__text:not(:first-child) {
  margin-top: 2px;
}

/**
 * Mode "image"
 */
.Banner--md-image .Banner__in {
  background-color: var(
    --content_tint_background,
    var(--vkui--color_background_secondary)
  );
}

.Banner--md-image .Banner__dismiss,
.Banner--inverted {
  color: var(--white, var(--vkui--color_text_contrast));
}

.Banner--inverted .Banner__subheader {
  color: var(--white, var(--vkui--color_text_contrast));
  opacity: 0.72;
}

/**
 * Size "s"
 */
.Banner--sz-s .Banner__before {
  margin-left: -4px;
}

/**
 * Size "m"
 */
.Banner--sz-m .Banner__in {
  padding: 16px;
}

.Banner--sz-m .Banner__subheader:not(:first-child),
.Banner--sz-m .Banner__text:not(:first-child) {
  margin-top: 4px;
}

.Banner--ios .Banner__dismiss,
.Banner--ios.Banner--md-image .Banner__dismiss {
  color: var(--icon_tertiary, var(--vkui--color_icon_tertiary));
}
