.ChipsSelect {
  position: relative;
}

.ChipsSelect__dropdown {
  cursor: pointer;
}

.ChipsSelect__icon {
  pointer-events: none;
}

.ChipsSelect__empty {
  padding: 12px 0;
  text-align: center;
  color: var(--text_secondary, var(--vkui--color_text_secondary));
}
