.GridAvatar__in {
  display: flex;
  flex-flow: column wrap;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: inherit;

  --grid-avatar-image-offset: 2px;
}

.GridAvatar__item {
  width: calc(50% - var(--grid-avatar-image-offset) / 2);
  height: calc(50% - var(--grid-avatar-image-offset) / 2);
  margin-right: var(--grid-avatar-image-offset);
  background-size: cover;
  background-position: center;
}

.GridAvatar__item:only-child {
  width: 100%;
}

.GridAvatar__item:first-child:not(:nth-last-child(4)),
.GridAvatar__item:nth-child(2):last-child {
  height: 100%;
}

/* если есть минимум три элемента — выбираем второй с конца */
.GridAvatar__item:nth-last-child(n + 3) ~ .GridAvatar__item:nth-last-child(2),
/* четвертый с конца */
.GridAvatar__item:nth-last-child(4) {
  margin-bottom: var(--grid-avatar-image-offset);
}
