.Radio {
  display: block;
  padding: 0 var(--vkui--size_base_padding_horizontal--regular);
}

.FormItem .Radio {
  width: 100%;
  box-sizing: content-box;
  margin: 0 calc(-1 * var(--vkui--size_base_padding_horizontal--regular));
}

.Radio__container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.Radio__container.Radio--reversed {
  flex-direction: row-reverse;
}

.Radio__input:disabled ~ .Radio__container {
  opacity: var(--vkui--opacity_disable_accessibility);
}

.Radio__icon {
  flex-shrink: 0;
  width: 22px;
  height: 22px;
  margin-right: 14px;
  color: var(--icon_tertiary, var(--vkui--color_icon_tertiary));
  transition: color 0.15s var(--ios-easing);
}

.Radio--reversed .Radio__icon {
  margin-right: 0;
  margin-left: 14px;
}

.Radio__input:checked ~ .Radio__container .Radio__icon {
  color: var(--accent, var(--vkui--color_icon_accent));
}

.Radio__icon .Radio__pin {
  transform-origin: 12px 12px;
  transform: scale(0);
  transition: transform 0.15s var(--ios-easing);
}

.Radio__input:checked ~ .Radio__container .Radio__icon .Radio__pin {
  transform: none;
}

.Radio__content {
  display: block;
  flex-grow: 1;
  max-width: 100%;
  color: var(--text_primary, var(--vkui--color_text_primary));
}

.Radio__wrapper {
  flex-grow: 1;
  max-width: 100%;
}

.Radio__description {
  display: block;
  color: var(--text_secondary, var(--vkui--color_text_secondary));
  margin-bottom: 12px;
  margin-top: 2px;
}

.Radio__children {
  margin-top: 12px;
}

.Radio__children:last-child {
  margin-bottom: 12px;
}

/**
 * sizeY COMPACT
 */

.Radio--sizeY-compact .Radio__children {
  margin-top: 8px;
}

.Radio--sizeY-compact .Radio__children:last-child {
  margin-bottom: 8px;
}

.Radio--sizeY-compact .Radio__description {
  margin-bottom: 7px;
}

.Radio--sizeY-compact .Radio__icon {
  width: 18px;
  height: 18px;
}

.Radio--sizeY-compact .Radio__icon::after {
  width: 12px;
  height: 12px;
}

/**
 * CMP:
 * RadioGroup
 */
.RadioGroup .Radio {
  flex: 1;
  margin: 0;
  box-sizing: border-box;
}
