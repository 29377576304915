.SegmentedControl {
  height: 30px;
  padding: 2px;
  border-radius: var(--vkui--size_border_radius--regular);
  background-color: var(--vkui--color_background_secondary);
  box-sizing: border-box;
}

.SegmentedControl--l {
  height: 36px;
}

.SegmentedControl__in {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  transition: all 100ms ease-out;
  border-radius: inherit;
}

.SegmentedControl__slider {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: var(--vkui--color_segmented_control);
  transition: transform 150ms;
  transform-origin: 0 0;
  border-radius: inherit;
  box-shadow: inset 0 0 0 0.5px var(--vkui--color_image_border_alpha),
    0 3px 8px rgba(0, 0, 0, 0.12), 0 3px 1px rgba(0, 0, 0, 0.04);
}

.SegmentedControl__option {
  position: relative;
  flex: 1;
  display: block;
  margin: 0;
  padding: 0;
  z-index: 1;
}

/**
 * sizeY REGULAR
 * TODO v5.0.0 поправить под новую адаптивность
 */
.SegmentedControl--sizeY-regular {
  height: 36px;
}

.SegmentedControl--sizeY-regular.SegmentedControl--l {
  height: 44px;
}
